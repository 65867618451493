import { LOCAL_STORAGE_KEY } from '../constants/config';

export function getLocalStorageKey(keyString, withoutPrefix = false) {
  return !withoutPrefix ? `${LOCAL_STORAGE_KEY.PREFIX}:${keyString}` : keyString;
}

export function get(key, withoutPrefix = false) {
  if (typeof localStorage === 'undefined')
    return undefined;

  const storageKey = getLocalStorageKey(key, withoutPrefix);
  const stringData = localStorage.getItem(storageKey);
  const numberRegex = /^[1-9.]+$/;
  const jsonRegex = /^[[|{].*[\]|}]$/;

  if (stringData === 'true' || stringData === 'false') {
    return stringData === 'true';
  }

  if (stringData === 'null')
    return null;

  if (stringData === 'undefined')
    return undefined;

  if (numberRegex.test(stringData)) {
    return Number(stringData);
  }

  if (jsonRegex.test(stringData)) {
    try {
      const data = JSON.parse(stringData);
      return data;
    } catch (error) {
      return stringData;
    }
  } 

  return stringData;
}

export function set(key, data, withoutPrefix = false) {
  if (typeof localStorage === 'undefined')
    return undefined;
  const storageKey = getLocalStorageKey(key, withoutPrefix);
  let stringData;

  try {
    if (typeof data === 'object') {
      stringData = JSON.stringify(data);
    } else {
      stringData = String(data);
    }
  } catch (error) {
    stringData = data;
  }

  return localStorage.setItem(storageKey, stringData);
}

export function remove(key, withoutPrefix = false) {
  return localStorage.removeItem(getLocalStorageKey(key, withoutPrefix));
}

export default {
  get, set, remove
}