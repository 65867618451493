import React, { useMemo } from 'react';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { compact } from 'lodash'
import { Typography } from '@mui/material';
import utils from '../../utils';

const Breadcrumb = ({ routes = {} }) => {
	const location = useLocation();
	// const [statusCode, setStoreStatusCode] = hooks.useLocalStorage(constants.Config.LOCAL_STORAGE_KEY.STATUS_CODE);
	const array = location.pathname.replace(routes.HOME.path, '').split('/').filter(i => i);

	const flattenRoutes = useMemo(() => utils.Common.flattenObject(routes), [routes]);
	
	const extraBreadcrumbItems = array.map((_, index) => {
		const items = array.slice(0, index + 1);
		const breadcrumbKey = items.join('.');
		const url = `${routes.HOME.path}/${array.slice(0, index + 1).join('/')}`;
		let currentComponent = (
			<Link key={index} to={url}>
				{breadcrumbKey}
			</Link>
		);
		
		Object.keys(flattenRoutes).every((key) => {
			if (url === flattenRoutes[key]) {
				const pathTitle = key.replace("path", "title");
				currentComponent = (
					<Typography 
						key={index} 
						sx={{
							color: 'black',
							fontFamily: 'Inter, sans-serif',
              fontSize: 14,
              fontWeight: 700
						}}>
						{flattenRoutes[pathTitle]}
					</Typography>
				);
				return false;
			}

			return true;
		});

		return currentComponent;
	});

	// const onRedirectHome = () => {
	// 	setStoreStatusCode("");
	// };

	if (array.length < 1 || array[array.length - 1] === "home" || array[array.length - 1] === "error") return (<></>);

	const breadcrumbItems = [
		<Link
			key="home"
			underline="hover"
			sx={{
				display: 'flex',
				alignItems: 'center'
			}}
			color="black"
			variant="subtitle2"
			href="/"
		>
			<HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />Home
		</Link>
	].concat(compact(extraBreadcrumbItems).slice(0));

	return (
		<Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
			{breadcrumbItems}
		</Breadcrumbs>
	);
}

export default Breadcrumb
