/* eslint-disable default-param-last */
import {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAIL,
  LIST_RESET,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAIL,
  IMPORT_REQUEST,
  IMPORT_SUCCESS,
  IMPORT_FAIL,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  EXPORT_FAIL,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAIL,
  LOCATION_CHART_REQUEST,
  LOCATION_CHART_SUCCESS,
  LOCATION_CHART_FAIL,
  EMPLOYEE_NAME_REQUEST,
  EMPLOYEE_NAME_SUCCESS,
  EMPLOYEE_NAME_FAIL,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAIL,
  EXPORT_RESET,
  IMPORT_RESET,
  CREATE_RESET,
  EDIT_RESET,
  DELETE_RESET,
} from '../types/employees';

export const employeesReducer = (state = { employees: [] }, action) => {
  switch (action.type) {
    case LIST_REQUEST:
      return {
        loading: true,
        employees: [],
      };

    case LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        employees: action.payload,
        total: action.total,
      };

    case LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        employees: [],
      };

    case LIST_RESET:
      return {
        employees: [],
      };

    default:
      return state;
  }
};

export const createEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_REQUEST:
      return {
        loading: true,
      };

    case CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const editEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_REQUEST:
      return {
        loading: true,
      };

    case EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case EDIT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const importEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_REQUEST:
      return { loading: true };

    case IMPORT_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case IMPORT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case IMPORT_RESET:
      return {};

    default:
      return state;
  }
};

export const exportEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_REQUEST:
      return { loading: true };

    case EXPORT_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case EXPORT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case EXPORT_RESET:
      return {};

    default:
      return state;
  }
};

export const getEmployeeNumberByLocationReducer = (state = { employeeByLocation: [] }, action) => {
  switch (action.type) {
    case LOCATION_CHART_REQUEST:
      return {
        loading: true,
        employeeByLocation: [],
      };

    case LOCATION_CHART_SUCCESS:
      return {
        loading: false,
        success: true,
        employeeByLocation: action.payload,
      };

    case LOCATION_CHART_FAIL:
      return {
        loading: false,
        error: action.payload,
        employeeByLocation: [],
      };

    default:
      return state;
  }
};

export const getEmployeeInfoByEmailReducer = (state = { employeeInfo: [] }, action) => {
  switch (action.type) {
    case EMPLOYEE_NAME_REQUEST:
      return {
        loading: true,
        employeeInfo: [],
      };

    case EMPLOYEE_NAME_SUCCESS:
      return {
        loading: false,
        success: true,
        employeeInfo: action.payload,
      };

    case EMPLOYEE_NAME_FAIL:
      return {
        loading: false,
        error: action.payload,
        employeeInfo: [],
      };

    default:
      return state;
  }
};

export const deleteEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REQUEST:
      return { loading: true };

    case DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case DELETE_RESET:
      return {};

    default:
      return state;
  }
};