import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '57vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(24, 0),
}));

export const PageTileStyle = {
    px: 5,
    mt: 10,
    mb: 5,
    fontWeight: 600,
    textAlign: 'center',
    color: '#003986',
    textTransform: 'uppercase'
}

export const FromTitleStyle = {
    textAlign: 'center',
    fontWeight: 600,
    color: '#09488A',
    marginBottom: 5 
}

export const StyledLoginRedirect = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
}));