export const LIST_REQUEST = 'WORKING_TIME_ASSIGNMENT_LIST_REQUEST';
export const LIST_SUCCESS = 'WORKING_TIME_ASSIGNMENT_LIST_SUCCESS';
export const LIST_FAIL = 'WORKING_TIME_ASSIGNMENT_LIST_FAIL';
export const LIST_RESET = 'WORKING_TIME_ASSIGNMENT_LIST_RESET';

export const RETRIEVE_REQUEST = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_REQUEST';
export const RETRIEVE_SUCCESS = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_SUCCESS';
export const RETRIEVE_FAIL = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_FAIL';
export const RETRIEVE_RESET = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_RESET';

export const CREATE_REQUEST = 'WORKING_TIME_ASSIGNMENT_CREATE_REQUEST';
export const CREATE_SUCCESS = 'WORKING_TIME_ASSIGNMENT_CREATE_SUCCESS';
export const CREATE_FAIL = 'WORKING_TIME_ASSIGNMENT_CREATE_FAIL';
export const RESET_AFTER_CREATE = 'RESET_AFTER_CREATING_WORKING_TIME_ASSIGNMENT';


export const ID_RETRIEVE_REQUEST = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_ID_REQUEST';
export const ID_RETRIEVE_SUCCESS = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_ID_SUCCESS';
export const ID_RETRIEVE_FAIL = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_ID_FAIL';
export const ID_RETRIEVE_RESET = 'WORKING_TIME_ASSIGNMENT_RETRIEVE_ID_RESET';

export const EDIT_REQUEST = 'WORKING_TIME_ASSIGNMENT_EDIT_REQUEST';
export const EDIT_SUCCESS = 'WORKING_TIME_ASSIGNMENT_EDIT_SUCCESS';
export const EDIT_FAIL = 'WORKING_TIME_ASSIGNMENT_EDIT_FAIL';
export const RESET_AFTER_EDIT = 'RESET_AFTER_UPDATING_WORKING_TIME_ASSIGNMENT';

export const DELETE_REQUEST = 'WORKING_TIME_ASSIGNMENT_DELETE_REQUEST';
export const DELETE_SUCCESS = 'WORKING_TIME_ASSIGNMENT_DELETE_SUCCESS';
export const DELETE_FAIL = 'WORKING_TIME_ASSIGNMENT_DELETE_FAIL';
export const RESET_AFTER_DELETE = 'RESET_AFTER_DELETING_WORKING_TIME_ASSIGNMENT';

export const IMPORT_REQUEST = 'IMPORT_WORKING_TIME_ASSIGNMENT_REQUEST';
export const IMPORT_SUCCESS = 'IMPORT_WORKING_TIME_ASSIGNMENT_SUCCESS';
export const IMPORT_FAIL = 'IMPORT_WORKING_TIME_ASSIGNMENT_FAIL';
export const IMPORT_RESET = 'IMPORT_WORKING_TIME_ASSIGNMENT_RESET';

export const EXPORT_REQUEST = 'EXPORT_WORKING_TIME_ASSIGNMENT_REQUEST';
export const EXPORT_SUCCESS = 'EXPORT_WORKING_TIME_ASSIGNMENT_SUCCESS';
export const EXPORT_FAIL = 'EXPORT_WORKING_TIME_ASSIGNMENT_FAIL';
export const EXPORT_RESET = 'EXPORT_WORKING_TIME_ASSIGNMENT_RESET';

