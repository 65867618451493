import axios from 'axios';
import { authService } from '../helpers/auth/authService';


const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    // TODO: implement when token is ready
    // let accessToken = "";
    // const tokenFromLS = authService.getToken();
    // if (tokenFromLS !== null && tokenFromLS.length > 0) {
    //   accessToken = tokenFromLS;
    // }
    config.responseType = 'json';
    if (config.data instanceof FormData) {
      config.headers = {
        Authorization: `Bearer ${authService.getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      };
    } else if (config.url === 'token') {
      config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    } else {
      config.headers = {
        Authorization: `Bearer ${authService.getToken()}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default axiosClient;
