/* eslint-disable default-param-last */
import {
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAIL,
  LIST_RESET,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAIL,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAIL,
  RETRIEVE_REQUEST,
  RETRIEVE_SUCCESS,
  RETRIEVE_FAIL,
  RETRIEVE_RESET,
  RESET_AFTER_CREATE,
  RESET_AFTER_EDIT,
  ID_RETRIEVE_REQUEST,
  ID_RETRIEVE_SUCCESS,
  ID_RETRIEVE_FAIL,
  ID_RETRIEVE_RESET,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAIL,
  RESET_AFTER_DELETE,
  IMPORT_REQUEST,
  IMPORT_SUCCESS,
  IMPORT_FAIL,
  EXPORT_REQUEST,
  EXPORT_SUCCESS,
  EXPORT_FAIL,
  IMPORT_RESET,
  EXPORT_RESET,
} from '../types/workingTimeAssignments';

const initialCreateState = {
  addButtonOpen: false,
  loading: '',
  success: '',
  error: '',
  result: '',
};

export const workingTimesAssignmentReducer = (state = { workingTimesAssignment: { results: [] } }, action) => {
  switch (action.type) {
    case LIST_REQUEST:
      return {
        loading: true,
        workingTimesAssignment: { results: [] },
      };

    case LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        success: true,
        workingTimesAssignment: action.payload,
        total: action.total,
      };
      return state;

    case LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        workingTimesAssignment: { results: [] },
      };

    case LIST_RESET:
      return {
        workingTimesAssignment: { results: [] },
      };

    default:
      return state;
  }
};

export const workingTimesAssignmentByEmailReducer = (
  state = { workingTimesAssignmentByEmail: { results: [] } },
  action
) => {
  switch (action.type) {
    case RETRIEVE_REQUEST:
      return {
        loading: true,
        workingTimesAssignmentByEmail: { results: [] },
      };

    case RETRIEVE_SUCCESS:
      state = {
        loading: false,
        success: true,
        workingTimesAssignmentByEmail: action.payload,
      };
      return state;

    case RETRIEVE_FAIL:
      return {
        loading: false,
        error: action.payload,
        workingTimesAssignmentByEmail: { results: [] },
      };

    case RETRIEVE_RESET:
      return {
        workingTimesAssignmentByEmail: { results: [] },
      };

    default:
      return state;
  }
};

export const workingTimesAssignmentByIdReducer = (state = { workingTimesAssignmentById: '' }, action) => {
  switch (action.type) {
    case ID_RETRIEVE_REQUEST:
      return {
        loading: true,
        workingTimesAssignmentById: '',
      };

    case ID_RETRIEVE_SUCCESS:
      state = {
        loading: false,
        success: true,
        workingTimesAssignmentById: action.payload,
      };
      return state;

    case ID_RETRIEVE_FAIL:
      return {
        loading: false,
        error: action.payload,
        workingTimesAssignmentById: '',
      };

    case ID_RETRIEVE_RESET:
      return {
        workingTimesAssignmentById: '',
      };

    default:
      return state;
  }
};

export const createWorkingTimesAssignmentReducer = (state = initialCreateState, action) => {
  switch (action.type) {
    case CREATE_REQUEST:
      return {
        loading: true,
      };

    case CREATE_SUCCESS:
      return {
        loading: false,
        addSuccess: true,
        addResult: action.payload,
      };

    case CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
        addResult: action.payload,
      };

    case RESET_AFTER_CREATE:
      return initialCreateState;

    default:
      return state;
  }
};

export const editWorkingTimesAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_REQUEST:
      return {
        loading: true,
      };

    case EDIT_SUCCESS:
      return {
        loading: false,
        editSuccess: true,
        editResult: action.payload,
      };

    case EDIT_FAIL:
      return {
        loading: false,
        error: action.payload,
        editResult: action.payload,
      };

    case RESET_AFTER_EDIT:
      return {};

    default:
      return state;
  }
};

export const deleteWorkingTimesAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REQUEST:
      return {
        loading: true,
      };

    case DELETE_SUCCESS:
      return {
        loading: false,
        deleteSuccess: true,
        deleteResult: action.payload,
      };

    case DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
        deleteResult: action.payload,
      };

    case RESET_AFTER_DELETE:
      return {
        deleteResult: '',
        error: '',
      };

    default:
      return state;
  }
};

export const importWorkingTimeAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_REQUEST:
      return { loading: true };

    case IMPORT_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case IMPORT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case IMPORT_RESET:
      return {};

    default:
      return state;
  }
};

export const exportWorkingTimeAssignmentReducer = (state = {}, action) => {
  switch (action.type) {
    case EXPORT_REQUEST:
      return { loading: true };

    case EXPORT_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case EXPORT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case EXPORT_RESET:
      return {};

    default:
      return state;
  }
};