const date = new Date();
export const today = date.toLocaleDateString();
export const firstDayofCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 2);
export const lastDayofCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

export const CreateLabelForWorkingTimeTable = (firstDay, lastDay) => {
  const textId = 'day_';
  let temp = 0;
  let label = [];
  for (let i = firstDay; i <= lastDay; i.setDate(i.getDate() + 1)) {
    temp += 1;
    const getDate = i.getDate();
    const dayName = i.toLocaleDateString('default', { weekday: 'short' }).toUpperCase();
    label = [
      ...label,
      {
        id: textId + temp,
        label: String(getDate).concat(' ', dayName),
        minWidth: 50,
        zIndex: 2,
        value: i.toLocaleDateString(),
      },
    ];
  }
  return label;
};

export const CreateLableForChartByMonth = () => {
  const dateCheck = new Date();
  let i = 5;
  const labels = [dateCheck.toLocaleString('default', { month: 'short' })];
  while (i > 0) {
    const priorMonth = new Date(dateCheck.setMonth(dateCheck.getMonth() - 1));
    labels.push(priorMonth.toLocaleString('default', { month: 'short' }));
    i -= 1;
  }
  return labels.reverse();
};

export const todayString = date.toISOString().split('T')[0];

export const CreateLableForVerticalBarChart = () => {
  const dateCheck = new Date();
  let i = 6;
  const labels = [dateCheck.toLocaleString('default', { day: 'numeric', month: 'short' })];
  while (i > 0) {
    const priorDay = new Date(dateCheck.setDate(dateCheck.getDate() - 1));
    labels.push(priorDay.toLocaleString('default', { day: 'numeric', month: 'short' }));
    i -= 1;
  }
  return labels.reverse();
};
