import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { compact, toArray, omit } from "lodash";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import './Header.css';
import { logout } from "../../redux/actions/authActions";

const settings = ['Logout'];

const Header = ({ routeDatas }) => {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	// const [statusCode, setStoreStatusCode] = Hooks.useLocalStorage(Constants.Config.LOCAL_STORAGE_KEY.STATUS_CODE);
	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = (e) => {
		setAnchorElNav(null);
		if (e.key) {
			// setStoreStatusCode("");
			navigate(e.path);
		}
	};

	const sessionLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
		sessionLogout();
	};

	const buildMenuDataFromRoutes = (routeItems) => {
		const routeData = toArray(omit(routeItems, ["HOME", "ERROR"]));
		const result = buildChildRoutes(routeData);
		return compact(result);
	};

	const buildChildRoutes = (routes) => (routes || []).filter(item => !item.hide).map((route) => {
			const result = {
				title: route.title,
				key: route.path,
				path: route.path
			};

			return result;
		});

	const getOpenKeys = () => {
		const pathSnippets = location.pathname
			.split("/")
			.filter((i) => i);

		return pathSnippets;
	};

	const renderMenu = (data) => {
		// TODO: check permission here
		const activeKeys = getOpenKeys();
		const isActive = activeKeys.includes(data.path.replace('/', ''));

		return (
			<Typography
				className="app-menu"
				key={data.key}
				onClick={() => handleCloseNavMenu(data)}
				sx={{
					ml: 8,
					my: 2,
					fontFamily: 'Inter, sans-serif',
					fontSize: 14,
					fontWeight: isActive ? 700 : 400,
					fontStyle: 'normal',
					color: 'black',
					display: 'block'
				}}
			>
				{data.title}
			</Typography>
		);
	};

	return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ height: 61, backgroundColor: '#C7C7C7' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ height: 61 }}>
            <img src="/static/images/logo_FSOFT.png" href="/home" alt="logo" className="logo" />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {buildMenuDataFromRoutes(routeDatas).map((page) => (
                  <MenuItem key={page.key} onClick={() => handleCloseNavMenu(page)}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {buildMenuDataFromRoutes(routeDatas).map((page) => renderMenu(page))}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Profile" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default Header;