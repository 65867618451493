/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET } from '../types/jobRanks';

export const jobRanksReducer = (state = { jobRanks: [] }, action) => {
  switch (action.type) {
    case LIST_REQUEST:
      return {
        loading: true,
        jobRanks: [],
      };

    case LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        jobRanks: action.payload.sort((a, b) => (a.name > b.name) ? 1 : -1),
        total: action.total
      };

    case LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        jobRanks: [],
      };

    case LIST_RESET:
      return {
        jobRanks: [],
      };

    default:
      return state;
  }
};
