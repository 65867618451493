import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { StyledLoginRedirect } from './LoginStyle';
import { authService } from '../../helpers/auth/authService';
import { login } from '../../redux/actions/authActions';

export const LoginRedirect = () => {
  const {
    instance,
    accounts: [account],
    inProgress,
  } = useMsal();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const token = authService.getToken();

  function systemLogin(token) {
    try {
      // Call API pass email param and get new token here
      dispatch(login(token));

      // After login redirect to home
      navigate(`dashboard`);
    } catch (err) {
      let error;
      if (err.status === 404) {
        error = 'Account is not registered. Please contact administration to register your account';
      } else {
        error = 'Error occur when login';
      }
      setErrorMessage(error);
    }
  }

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ['User.Read'],
          account: account,
        })
        .then((response) => {
          if (response) {
            const token = response.accessToken;
            systemLogin(token);
          }
        });
    }
  }, [navigate, instance, account, dispatch]);

  useEffect(() => {
    if (token) {
      navigate(`dashboard`);
    }
  }, []);

  useEffect(() => {
    if (!account && inProgress === 'none') {
      navigate('/login');
    }
  }, [account, navigate, inProgress]);

  if (errorMessage) return <StyledLoginRedirect>{errorMessage}</StyledLoginRedirect>;

  return (
    <StyledLoginRedirect>
      <p>Signing in with Microsoft account</p>
    </StyledLoginRedirect>
  );
};
