/* eslint-disable default-param-last */
import { LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET } from '../types/childDepartments';

export const childDepartmentsReducer = (state = { childDepartments: [] }, action) => {
    switch (action.type) {
      case LIST_REQUEST:
        return {
          loading: true,
          childDepartments: [],
        };
  
      case LIST_SUCCESS:
        return {
          loading: false,
          success: true,
          childDepartments: action.payload,
          total: action.total
        };
  
      case LIST_FAIL:
        return {
          loading: false,
          error: action.payload,
          childDepartments: [],
        };
  
      case LIST_RESET:
        return {
          departments: [],
        };
  
      default:
        return state;
    }
  };