
export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_SSO_CLIENTID}`,
    authority: `${process.env.REACT_APP_SSO_AUTHORITY}`,
    redirectUri: `${process.env.REACT_APP_SSO_REDIRECT_URI}`,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
};