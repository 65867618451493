import { applyMiddleware, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loginReducer } from './reducers/authReducers';
import {
  employeesReducer,
  createEmployeeReducer,
  importEmployeeReducer,
  exportEmployeeReducer,
  editEmployeeReducer,
  getEmployeeNumberByLocationReducer,
  getEmployeeInfoByEmailReducer,
  deleteEmployeeReducer,
} from './reducers/employeesReducers';
import { locationsReducer } from './reducers/locationReducers';
import { jobRanksReducer } from './reducers/jobRankReducers';
import { departmentsReducer } from './reducers/departmentReducers';
import { childDepartmentsReducer } from './reducers/childDepartmentReducers';
import { workingTimesReducer } from './reducers/workingTimeReducers';
import { workingTypesReducer } from './reducers/workingTypeReducers';
import { dateReducer } from './reducers/dateReducers';
import {
  workingTimesAssignmentReducer,
  workingTimesAssignmentByEmailReducer,
  workingTimesAssignmentByIdReducer,
  createWorkingTimesAssignmentReducer,
  editWorkingTimesAssignmentReducer,
  deleteWorkingTimesAssignmentReducer,
  importWorkingTimeAssignmentReducer,
  exportWorkingTimeAssignmentReducer,
} from './reducers/workingTimeAssignmentReducers';
import { projectsReducer } from './reducers/projectReducers';
import { workingPlacesReducer } from './reducers/workingPlaceReducers';
import { workingTimeDetailReducer } from './reducers/workingTimeDetailReducers';

const reducer = combineReducers({
  auth: loginReducer,
  listEmployee: employeesReducer,
  createEmployee: createEmployeeReducer,
  editEmployee: editEmployeeReducer,
  importEmployee: importEmployeeReducer,
  exportEmployee: exportEmployeeReducer,
  getEmployeeNumberByLocation: getEmployeeNumberByLocationReducer,
  getEmployeeInfoByEmail: getEmployeeInfoByEmailReducer,
  listLocation: locationsReducer,
  listJobRank: jobRanksReducer,
  listDepartment: departmentsReducer,
  listChildDepartment: childDepartmentsReducer,
  listWorkingTime: workingTimesReducer,
  listWorkingType: workingTypesReducer,
  listProject: projectsReducer,
  listWorkingPlace: workingPlacesReducer,
  loadDate: dateReducer,
  listWorkingTimeAssignment: workingTimesAssignmentReducer,
  workingTimeAssignmentByEmail: workingTimesAssignmentByEmailReducer,
  workingTimeAssignmentById: workingTimesAssignmentByIdReducer,
  createWorkingTimeAssignment: createWorkingTimesAssignmentReducer,
  editWorkingTimeAssignment: editWorkingTimesAssignmentReducer,
  deleteWorkingTimeAssignment: deleteWorkingTimesAssignmentReducer,
  importWorkingTimeAssignment: importWorkingTimeAssignmentReducer,
  listWorkingTimeDetail: workingTimeDetailReducer,
  deleteEmployee: deleteEmployeeReducer,
  exportWorkingTimeAssignment: exportWorkingTimeAssignmentReducer,
});

const middleware = [thunk];

const store = configureStore({ reducer }, composeWithDevTools({ mageAge: 200 })(applyMiddleware(...middleware)));

export default store;
