import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LoadingIndicator from '../../components/Loading/Loading';
import { authService } from './authService';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = authService.getToken();
  const userLogin = useSelector((state) => state.auth);
  const { loading } = userLogin;

  useEffect(() => {
    if (!loading && (!token || authService.isTokenExpire(token))) {
      authService.deleteToken();
      navigate(`/login${location.pathname ? `?redirect=${location.pathname}` : ''}`);
    }
  }, [token]);

  return (
    <>
      {token ? 
        children : 
        <ContentBox>
          <LoadingIndicator />
        </ContentBox>
      }
    </>
  );
};

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  width: '100vw',
  height: '100vh'
}));

export default AuthProvider;
