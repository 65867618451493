import React from 'react';
import { AuthenticatedTemplate } from "@azure/msal-react";

import MainLayout from "../MainLayout/MainLayout";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <AuthenticatedTemplate>
      <MainLayout {...rest}>
        <Component />
      </MainLayout>
    </AuthenticatedTemplate>
  )

export default PrivateRoute;
