import React, { useState } from 'react';
import * as Yup from 'yup';
import { TextField, IconButton, InputAdornment, Button, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useFormik, Form, FormikProvider } from 'formik';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../common/authConfig';

const LoginForm = () => { 
	const { instance } = useMsal();
	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};
	// Login SSO
	const handleMicrosoftLogin = async () => {
		try {
			await instance.loginRedirect(loginRequest);
		} catch (error) {
			const err = error.data;
			console.log(err);
		}
	};
	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Email must be a valid email address').required('Email is required'),
		password: Yup.string().required('Password is required'),
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: LoginSchema,
	});
	const { errors, touched, getFieldProps } = formik;

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate>
				<Stack spacing={3}>
					<TextField
						fullWidth
						autoComplete="username"
						type="email"
						placeholder='Username'
						{...getFieldProps('email')}
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
						data-testid="username-input" />
					<TextField
						fullWidth
						autoComplete="password"
						type={showPassword ? 'text' : 'password'}
						placeholder='Password'
						{...getFieldProps('password')}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton onClick={handleShowPassword} edge="end">
										{!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
						data-testid="password-input" />
				</Stack>
				<Button
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					sx={{ fontWeight: 5, fontSize: 16, marginTop: 8 }}
					onClick={() => { console.log('Handle submit'); } }
				>
					Login
				</Button>
				<Button
					fullWidth
					size="large"
					variant="outlined"
					sx={{ marginTop: 5, color: 'black', fontWeight: 5, fontSize: 16 }}
					onClick={handleMicrosoftLogin}
				>
					Sign in with FSOFT Account
				</Button>
			</Form>
		</FormikProvider>



	);
}

export default LoginForm;
