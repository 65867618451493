import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Pages from './pages';
import NotFound from './pages/Page404';
import PrivateRoute from './modules/PrivateRoute/PrivateRoute';
import Login from './pages/Login/Login';
import { LoginRedirect } from './pages/Login/LoginRedirect';
import AuthProvider from './helpers/auth/AuthProvider';

// ----------------------------------------------------------------------

const MainRoutes = () => (
  <Routes>
    <Route path="/" exact element={<LoginRedirect />} />
    <Route path="/login" exact element={<Login />} />
    <Route
      path="/*"
      element={
        <AuthProvider>
          <Routes>
            <Route path="/home" exact element={<PrivateRoute component={Pages.Home} />} />
            <Route path="/employees" exact element={<PrivateRoute component={Pages.Employees} />} />
            <Route path="/assignments" exact element={<PrivateRoute component={Pages.Assignments} />} />
            <Route path="/dashboard" exact element={<PrivateRoute component={Pages.Dashboard} />} />
          </Routes>
        </AuthProvider>
      }
    />
    <Route path="/error" exact element={<PrivateRoute component={NotFound} />} />
  </Routes>
);

export default MainRoutes;
