import React, { Suspense } from 'react';
// SSO login
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import MainRoutes from './routes';
// components
import PageLoading from './modules/PageLoading/PageLoading';
import { msalConfig } from './common/authConfig';
// ----------------------------------------------------------------------
const msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <HelmetProvider>
        <BrowserRouter>
          <Suspense fallback={<PageLoading />}>
            <MainRoutes />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </MsalProvider>
  );
}
