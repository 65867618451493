import jwtDecode from 'jwt-decode';
import { LOCAL_STORAGE_KEY } from '../../constants/config';

export const authService = {
	getToken: () => localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_KEY) || '',
  isTokenExpire: (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp < new Date().getTime() / 1000;
  },
  setToken: (token) => {
    localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_KEY, token);
  },
  deleteToken: () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_KEY);
  }
};
