import React from 'react'

import Breadcrumb from '../Breadcrumb/Breadcrumb';

import { PageWrapper } from './PageLayout.styled';

const PageLayout = ({ routes, children }) => (
  <PageWrapper>
    <Breadcrumb routes={routes} />
    {children}
  </PageWrapper>
);

export default PageLayout;
