/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */
export const flattenObject = (ob) => {
	const toReturn = {};
	for (const i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		if (typeof ob[i] === "object" && ob[i] !== null && !Array.isArray(ob[i])) {
			const flatObject = flattenObject(ob[i]);
			for (const x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				toReturn[`${i}.${x}`] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}

	return toReturn;
};

export const removeObjectNull = (object) => {
	if (object) {
		for (const propName in object) {
			if (object[propName] === null || object[propName] === undefined) {
				delete object[propName];
			}
		}
	}

	return object;
};

export const isValidEmail = (str) => {
	return (/$^|.+@.+..+/).test(str);
};