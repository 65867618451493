export const LIST_REQUEST = 'EMPLOYEE_LIST_REQUEST';
export const LIST_SUCCESS = 'EMPLOYEE_LIST_SUCCESS';
export const LIST_FAIL = 'EMPLOYEE_LIST_FAIL';
export const LIST_RESET = 'EMPLOYEE_LIST_RESET';

export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';
export const CREATE_RESET = 'CREATE_RESET';

export const EDIT_REQUEST = 'EDIT_REQUEST';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAIL = 'EDIT_FAIL';
export const EDIT_RESET = 'EDIT_RESET';

export const IMPORT_REQUEST = 'IMPORT_REQUEST';
export const IMPORT_SUCCESS = 'IMPORT_SUCCESS';
export const IMPORT_FAIL = 'IMPORT_FAIL';
export const IMPORT_RESET = 'IMPORT_RESET';

export const EXPORT_REQUEST = 'EXPORT_REQUEST';
export const EXPORT_SUCCESS = 'EXPORT_SUCCESS';
export const EXPORT_FAIL = 'EXPORT_FAIL';
export const EXPORT_RESET = 'EXPORT_RESET';

export const LOCATION_CHART_REQUEST = 'LOCATION_CHART_REQUEST';
export const LOCATION_CHART_SUCCESS = 'LOCATION_CHART_SUCCESS';
export const LOCATION_CHART_FAIL = 'LOCATION_CHART_FAIL';

export const EMPLOYEE_NAME_REQUEST = 'EMPLOYEE_NAME_REQUEST';
export const EMPLOYEE_NAME_SUCCESS = 'EMPLOYEE_NAME_SUCCESS';
export const EMPLOYEE_NAME_FAIL = 'EMPLOYEE_NAME_FAIL';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';
export const DELETE_RESET = 'DELETE_RESET';