import React from "react";
import { Container, Typography } from '@mui/material';
import LoginForm from "./LoginForm";
import { RootStyle, SectionStyle, ContentStyle, PageTileStyle, FromTitleStyle } from "./LoginStyle";


const Login = () => (
		<RootStyle>
			<SectionStyle>
				<Typography variant="h2" sx={PageTileStyle}>
					Working Time Management
				</Typography>
				<img src="/static/images/login-background.png" alt="login" />
			</SectionStyle>

			<Container maxWidth="sm">
				<ContentStyle>
					<Typography variant="h3" sx={FromTitleStyle}>
						Login
					</Typography>
					<LoginForm />
				</ContentStyle>
			</Container>

		</RootStyle>
	);

export default Login;