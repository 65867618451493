import tokenAuthApi from '../../apis/tokenAuthApi';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from '../types/auth';
import { authService } from '../../helpers/auth/authService';

export const login = (token) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const result = await tokenAuthApi.login({ token });
    authService.setToken(result.access_token);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: result,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.response && error.response.data.detail ? error.response.data.detail : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  authService.deleteToken();
  dispatch({ type: LOGOUT });
};
