import React from 'react';
import { Paper } from '@mui/material';

import constants from '../../constants';
import utils from '../../utils';

import PageLayout from '../PageLayout/PageLayout';
import Header from '../Header/Header';
import ROUTES from '../../constants/Routes';

import './MainLayout.css';

const MainLayout = ({ children, withoutPageLayout = false, ...rest  }) => {
	const headerHeight = utils.LocalStorage.get(constants.Config.LOCAL_STORAGE_KEY.HEADER_HEIGHT) || constants.Layout.HEADER_HEIGHT;
	const contentMaxHeightStyle = `calc(100vh - ${headerHeight + 36}px)`;
	// const [statusCode, setStoreStatusCode] = hooks.useLocalStorage(constants.Config.LOCAL_STORAGE_KEY.STATUS_CODE);
	
	return (
		<div className="app-wrapper flex flex-row flex-1">
			<Header routeDatas={ROUTES} />
			<Paper
				className="app-content flex-1 d-block overflow-x-auto"
				elevation={0}
				square
				style={{
					maxHeight: contentMaxHeightStyle,
					marginTop: '16px',
					overflow: 'auto',
				}}>
				{withoutPageLayout ? children : (
					<PageLayout routes={ROUTES}>
						{children}
					</PageLayout>
				)}
			</Paper>
		</div>
	);
}

export default MainLayout;
