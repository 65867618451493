import React from 'react'
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import MainLayout from "../MainLayout/MainLayout";

const PageWrapper = styled('div')({
  borderColor: 'inherit',
  boxShadow: 'none',
  paddingRight: 50,
  paddingLeft: 50
});

const LoadingContent = () => <PageWrapper className="pt-8"><Card><Skeleton /></Card></PageWrapper>;

const PageLoading = ({ withMainLayout = true }) => withMainLayout ? (
  <MainLayout withoutPageLayout="true">
    <LoadingContent />
  </MainLayout>
) : (
  <LoadingContent />
);

export default PageLoading;
