/* eslint-disable default-param-last */
/* eslint-disable default-case */
import {LIST_REQUEST, LIST_SUCCESS, LIST_FAIL, LIST_RESET} from '../types/workingTimeDetail'


export const workingTimeDetailReducer = (state = { workingTimeDetail: { results: null } }, action) => {
    switch (action.type) {
        case LIST_REQUEST:
            return {
                loading: true,
                workingTimeDetail: { results: null }
            };
        
        case LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                success: true,
                workingTimeDetail: {results: action.payload},
            };
            return state;
        
        case LIST_FAIL:
            return {
                loading: false,
                error: action.payload,
                workingTimeDetail: { result: null },
            };
        
        case LIST_RESET:
            return {
                loading:false,
                workingTimeDetail: { result: null },
            };
        
        default:
            return state;
    };
}