import React from "react";
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export default function LoadingIndicator({ color = '#6750A4', ...props }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: { color },
          opacity: 0.3,
        }}
        size={80}
        thickness={6}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: { color },
          animationDuration: '600ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={80}
        thickness={6}
        {...props}
      />
    </Box>
  );
}
