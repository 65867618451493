export const REFRESH_TOKEN_DELAY_TIMEOUT = 15 * 60 * 1000; // 15min

export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN_KEY: 'access_token',
  PREFIX: 'fpt:wtm',
  OIDC_USER: 'oidc:user',
  HEADER_HEIGHT: 'header_height',
  TABLE_CONFIG: 'table_config',
  STATUS_CODE: 'status_code',
  ERROR_MESSAGE: 'error_message',
  PROPERTY_OBJ: 'propertyObj',
};

export const WorkingTimeData = {
  'Work in office': 'WIO',
  'Work from home': 'WFH',
  Hybrid: 'HYB',
};

export const stickyColumnTableWorkingTime = [
  {
    id: 'employee',
    label: 'EMAIL',
    align: 'center',
    minWidth: 80,
    position: 'sticky',
    left: 0,
    zIndex: 5,
  },
  {
    id: 'child_department',
    label: 'CHILD DEPARTMENT',
    align: 'center',
    minWidth: 100,
    position: 'sticky',
    left: 112,
    zIndex: 5,
  },
  {
    id: 'project',
    label: 'PROJECT',
    align: 'center',
    minWidth: 80,
    position: 'sticky',
    left: 244,
    zIndex: 5,
  },
];