/* eslint-disable default-param-last */
import { LOAD_DATE } from '../types/date';
import { firstDayofCurrentMonth, lastDayofCurrentMonth } from '../../utils/date';

export const dateReducer = (
  state = {
    dateRange: {
      firstDate: firstDayofCurrentMonth.toISOString().slice(0, 10),
      lastDate: lastDayofCurrentMonth.toISOString().slice(0, 10),
    },
  },
  action
) => {
  switch (action.type) {
    case LOAD_DATE:
      return {
        ...state,
        dateRange: action.payload,
      };

    default:
      return state;
  }
};
